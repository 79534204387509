const routes = [
    {
        name: 'Index', //首页
        path: '/Index',
        component: () => import('../components/Index.vue')
    },
    {
        name: 'IndexAgent', //首页
        path: '/IndexAgent',
        component: () => import('../components/IndexAgent.vue')
    },
    {
        name: 'Administrator', //首页
        path: '/Administrator',
        component: () => import('../components/Administrator.vue')
    },
    {
        name: 'Profiles',  //会员文档
        path: '/Profiles',
        component: () => import('../components/Profiles.vue')
    },
    {
        name: 'ProfilesFamily',  //会员文档
        path: '/ProfilesFamily',
        component: () => import('../components/ProfilesFamily.vue')
    },
    {
        name: 'ProfilesTab',  //会员文档
        path: '/ProfilesTab',
        component: () => import('../components/ProfilesTab.vue')
    },
    {
        name: 'ProfilesMember',  //会员文档详情
        path: '/ProfilesMember',
        component: () => import('../components/ProfilesMember.vue')
    },
    {
        name: 'ProfilesReceipt',  //会员检查
        path: '/ProfilesReceipt',
        component: () => import('../components/ProfilesReceipt.vue')
    },
    {
        name: 'ProfilesCase',  //会员病历详情
        path: '/ProfilesCase',
        component: () => import('../components/ProfilesCase.vue')
    },
    {
        name: 'ProfilesOptometry',  //会员验光单详情
        path: '/ProfilesOptometry',
        component: () => import('../components/ProfilesOptometry.vue')
    },
    {
        name: 'ProfilesReport',  //会员眼科详情
        path: '/ProfilesReport',
        component: () => import('../components/ProfilesReport.vue')
    },
    {
        name: 'Employees',  //员工列表
        path: '/Employees',
        component: () => import('../components/Employees.vue')
    },
    {
        name: 'Billing',  //开单
        path: '/Billing',
        component: () => import('../components/Billing.vue')
    },
    {
        name: 'BillingTest',  //开单
        path: '/BillingTest',
        component: () => import('../components/BillingTest.vue')
    },
    {
        name: 'Branch',  //开单
        path: '/Branch',
        component: () => import('../components/Branch.vue')
    },
    {
        name: 'Blanck',  //开单
        path: '/Blanck',
        component: () => import('../components/Blanck.vue')
    },
    {
        name: 'Return',  //开单
        path: '/Return',
        component: () => import('../components/Return.vue')
    },
    {
        name: 'ReturnApply',  //开单
        path: '/ReturnApply',
        component: () => import('../components/ReturnApply.vue')
    },
    {
        name: 'GspAccept',  //多店管理
        path: '/GspAccept',
        component: () => import('../components/GspAccept.vue')
    },
    {
        name: 'GspBatch',  //多店管理
        path: '/GspBatch',
        component: () => import('../components/GspBatch.vue')
    },
    {
        name: 'GSPValidity',  //多店管理
        path: '/GSPValidity',
        component: () => import('../components/GSPValidity.vue')
    },
    {
        name: 'GspCommodity',  //多店管理
        path: '/GspCommodity',
        component: () => import('../components/GspCommodity.vue')
    },
    {
        name: 'GspDisinfect',  //多店管理
        path: '/GspDisinfect',
        component: () => import('../components/GspDisinfect.vue')
    },
    {
        name: 'GspFirm',  //多店管理
        path: '/GspFirm',
        component: () => import('../components/GspFirm.vue')
    },

    {
        name: 'Product',  //多店管理
        path: '/Product',
        component: () => import('../components/Product.vue')
    },
    {
        name: 'ProductCheck',  //多店管理
        path: '/ProductCheck',
        component: () => import('../components/ProductCheck.vue')
    },
    {
        name: 'ProductCheckN',  //多店管理
        path: '/ProductCheckN',
        component: () => import('../components/ProductCheckN.vue')
    },
    {
        name: 'ProductCheckA',  //多店管理
        path: '/ProductCheckA',
        component: () => import('../components/ProductCheckA.vue')
    },
    {
        name: 'ProductCheckD',  //多店管理
        path: '/ProductCheckD',
        component: () => import('../components/ProductCheckD.vue')
    },
    {
        name: 'ProductTest',  //多店管理
        path: '/ProductTest',
        component: () => import('../components/ProductTest.vue')
    },
    {
        name: 'ProductNews',  //新增产品
        path: '/ProductNews',
        component: () => import('../components/ProductNews.vue')
    },
    {
        name: 'ProductLog',  //新增产品
        path: '/ProductLog',
        component: () => import('../components/ProductLog.vue')
    },
    {
        name: 'ProductNewsTest',  //新增产品
        path: '/ProductNewsTest',
        component: () => import('../components/ProductNewsTest.vue')
    },
    {
        name: 'ProductStock',  //产品库存
        path: '/ProductStock',
        component: () => import('../components/ProductStock.vue')
    },
    {
        name: 'ProductRevise',  //产品修改
        path: '/ProductRevise',
        component: () => import('../components/ProductRevise.vue')
    },
    {
        name: 'ProductReviseTest',  //产品修改
        path: '/ProductReviseTest',
        component: () => import('../components/ProductReviseTest.vue')
    },
    {
        name: 'ProductPackage',  //产品修改
        path: '/ProductPackage',
        component: () => import('../components/ProductPackage.vue')
    },
    {
        name: 'ProductEntry',  //产品修改
        path: '/ProductEntry',
        component: () => import('../components/ProductEntry.vue')
    },
    {
        name: 'ProductTransfers',  //新增产品
        path: '/ProductTransfers',
        component: () => import('../components/ProductTransfers.vue')
    },
    {
        name: 'ProductTransfersI',  //新增产品
        path: '/ProductTransfersI',
        component: () => import('../components/ProductTransfersI.vue')
    },
    {
        name: 'ProductTransfersN',  //新增产品
        path: '/ProductTransfersN',
        component: () => import('../components/ProductTransfersN.vue')
    },
    {
        name: 'ProcessApply',  //加工定制
        path: '/ProcessApply',
        component: () => import('../components/ProcessApply.vue')
    },
    {
        name: 'ProcessUser',  //加工定制
        path: '/ProcessUser',
        component: () => import('../components/ProcessUser.vue')
    },
    {
        name: 'ProcessDetails',  //加工定制
        path: '/ProcessDetails',
        component: () => import('../components/ProcessDetails.vue')
    },
    {
        name: 'ProductRecord',
        path: '/ProductRecord',
        component: () => import('../components/ProductRecord.vue')
    },
    {
        name: 'ProcessList',  //加工列表
        path: '/ProcessList',
        component: () => import('../components/ProcessList.vue')
    },
    {
        name: 'ProductClassify',  //加工分类
        path: '/ProductClassify',
        component: () => import('../components/ProductClassify.vue')
    },
    {
        name: 'RepleAll',  //采购
        path: '/RepleAll',
        component: () => import('../components/RepleAll.vue')
    },
    {
        name: 'Stock',  //采购
        path: '/Stock',
        component: () => import('../components/Stock.vue')
    },
    {
        name: 'StockAll',  //采购
        path: '/StockAll',
        component: () => import('../components/StockAll.vue')
    },
    {
        name: 'StockWare',  //采购
        path: '/StockWare',
        component: () => import('../components/StockWare.vue')
    },
    {
        name: 'StockDetails',  //采购
        path: '/StockDetails',
        component: () => import('../components/StockDetails.vue')
    },
    {
        name: 'StockAllApply',  //采购
        path: '/StockAllApply',
        component: () => import('../components/StockAllApply.vue')
    },
    {
        name: 'StockApply',  //采购
        path: '/StockApply',
        component: () => import('../components/StockApply.vue')
    },
    {
        name: 'StockApplyTest',  //采购
        path: '/StockApplyTest',
        component: () => import('../components/StockApplyTest.vue')
    },
    {
        name: 'Manufacturer',  //添加供货商
        path: '/Manufacturer',
        component: () => import('../components/Manufacturer.vue')
    },
    {
        name: 'ManufacturerDetails',  //添加供货商
        path: '/ManufacturerDetails',
        component: () => import('../components/ManufacturerDetails.vue')
    },
    {
        name: 'Supplier',  //添加供货商
        path: '/Supplier',
        component: () => import('../components/Supplier.vue')
    },
    {
        name: 'SupplyBarter',  //供货商换货
        path: '/SupplyBarter',
        component: () => import('../components/SupplyBarter.vue')
    },
    {
        name: 'SupplyOrder',  //供货商发货
        path: '/SupplyOrder',
        component: () => import('../components/SupplyOrder.vue')
    },
    {
        name: 'SupplyDetails',  //加工定制
        path: '/SupplyDetails',
        component: () => import('../components/SupplyDetails.vue')
    },
    {
        name: 'SupplyMinute',  //加工定制
        path: '/SupplyMinute',
        component: () => import('../components/SupplyMinute.vue')
    },
    {
        name: 'MarketingCard',  //计次卡
        path: '/MarketingCard',
        component: () => import('../components/MarketingCard')
    },
    {
        name: 'MarketingAcrive',  //计次卡
        path: '/MarketingAcrive',
        component: () => import('../components/MarketingAcrive.vue')
    },
    {
        name: 'MarketingIntegral',  //积分
        path: '/MarketingIntegral',
        component: () => import('../components/MarketingIntegral')
    },
    {
        name: 'MarketingRecharge',  //分销
        path: '/MarketingRecharge',
        component: () => import('../components/MarketingRecharge')
    },
    {
        name: 'MarketingSeckill',  //秒杀
        path: '/MarketingSeckill',
        component: () => import('../components/MarketingSeckill')
    },
    {
        name: 'Breakage',  //报损
        path: '/Breakage',
        component: () => import('../components/Breakage.vue')
    },
    {
        name: 'BreakageApply',  //报损
        path: '/BreakageApply',
        component: () => import('../components/BreakageApply.vue')
    },
    {
        name: 'BreakageDetails',  //报损
        path: '/BreakageDetails',
        component: () => import('../components/BreakageDetails.vue')
    },
    {
        name: 'EyeOptometry',  //报损
        path: '/EyeOptometry',
        component: () => import('../components/EyeOptometry.vue')
    },
    {
        name: 'Order',  //销售订单
        path: '/Order',
        component: () => import('../components/Order.vue')
    },
    {
        name: 'OrderDetails',  //销售详情
        path: '/OrderDetails',
        component: () => import('../components/OrderDetails.vue')
    },
    {
        name: 'ChartSale',  //销售图表
        path: '/Chart',
        component: () => import('../components/ChartSale.vue')
    },
    {
        name: 'ChartProfit',  //利润图表
        path: '/ChartProfit',
        component: () => import('../components/ChartProfit.vue')
    },
    {
        name: 'ChartCost',  //成本图表
        path: '/ChartCost',
        component: () => import('../components/ChartCost.vue')
    },
    {
        name: 'ChartStore',  //成本图表
        path: '/ChartStore',
        component: () => import('../components/ChartStore.vue')
    },
    {
        name: 'ChartEmployer',  //成本图表
        path: '/ChartEmployer',
        component: () => import('../components/ChartEmployer.vue')
    },
    {
        name: 'ChartProfiles',  //成本图表
        path: '/ChartProfiles',
        component: () => import('../components/ChartProfiles.vue')
    },
    {
        name: 'Message',  //短信
        path: '/Message',
        component: () => import('../components/Message.vue')
    },
    {
        name: 'MessageSend',  //短信
        path: '/MessageSend',
        component: () => import('../components/MessageSend.vue')
    },
    {
        name: 'MedicalList',  //短信
        path: '/MedicalList',
        component: () => import('../components/MedicalList.vue')
    },
    {
        name: 'MedicalBatch',  //短信
        path: '/MedicalBatch',
        component: () => import('../components/MedicalBatch.vue')
    },
    {
        name: 'Batch',  //批号
        path: '/Batch',
        component: () => import('../components/Batch.vue')
    },
    {
        name: 'MedicalClassify',  //短信
        path: '/MedicalClassify',
        component: () => import('../components/MedicalClassify.vue')
    },
    {
        name: 'MedicalRevise',  //短信
        path: '/MedicalRevise',
        component: () => import('../components/MedicalRevise.vue')
    },
    {
        name: 'MedicalAdd',  //短信
        path: '/MedicalAdd',
        component: () => import('../components/MedicalAdd.vue')
    },
    {
        name: 'SetBasis',  //设置
        path: '/SetBasis',
        component: () => import('../components/SetBasis.vue')
    },
    {
        name: 'setCommission',  //设置
        path: '/setCommission',
        component: () => import('../components/setCommission.vue')
    },
    {
        name: 'SetWechat',  //设置
        path: '/SetWechat',
        component: () => import('../components/SetWechat')
    },
    {
        name: 'EyeRegister',  //眼科登记
        path: '/EyeRegister',
        component: () => import('../components/EyeRegister.vue')
    },
    {
        name: 'SetCard',  //眼科登记
        path: '/SetCard',
        component: () => import('../components/SetCard.vue')
    },
    {
        name: 'SetReduction',  //眼科登记
        path: '/SetReduction',
        component: () => import('../components/SetReduction.vue')
    },
    {
        name: 'EyeItem',  //眼科条例
        path: '/EyeItem',
        component: () => import('../components/EyeItem.vue')
    },
    {
        name: 'EyePackage',  //眼科套餐
        path: '/EyePackage',
        component: () => import('../components/EyePackage.vue')
    },
    {
        name: 'EyeCheck',  //眼科检查
        path: '/EyeCheck',
        component: () => import('../components/EyeCheck.vue')
    },
    {
        name: 'EyeVisit',  //眼科检查
        path: '/EyeVisit',
        component: () => import('../components/EyeVisit.vue')
    },
    // {
    //     name: 'EyeCharge',  //眼科检查
    //     path: '/EyeCharge',
    //     component: () => import('../components/EyeCharge.vue')
    // },
    {
        name: 'EyeChargeDetails',  //眼科检查
        path: '/EyeChargeDetails',
        component: () => import('../components/EyeChargeDetails.vue')
    },
    {
        name: 'EyeCustomer',  //眼科检查
        path: '/EyeCustomer',
        component: () => import('../components/EyeCustomer.vue')
    },
    {
        name: 'EyeReport',  //眼科检查
        path: '/EyeReport',
        component: () => import('../components/EyeReport.vue')
    },
    {
        name: 'EyeReportDetails',  //眼科检查
        path: '/EyeReportDetails',
        component: () => import('../components/EyeReportDetails.vue')
    },
    {
        name: 'EyeCaseDetails',  //眼科检查
        path: '/EyeCaseDetails',
        component: () => import('../components/EyeCaseDetails.vue')
    },
    {
        name: 'EyeSelectDetails',  //眼科检查
        path: '/EyeSelectDetails',
        component: () => import('../components/EyeSelectDetails.vue')
    },
    {
        name: 'EyeRegistered',  //眼科检查
        path: '/EyeRegistered',
        component: () => import('../components/EyeRegistered.vue')
    },
    // {
    //     name: 'AlertComponents',
    //     path: '/AlertComponents',
    //     component: () => import('../components/AlertComponents.vue')
    // },
    {
        name: 'Test',
        path: '/Test',
        component: () => import('../components/Test.vue')
    }
];

export default routes